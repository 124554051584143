//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            page: 1,
            addressList: [],
            dialogVisible: false,
            form: {
                contact: '',
                contact_number: '',
                address: '',
                house_number: '',
                is_default: true,
                province: '',
                city: '',
                district: '',
                longitude: '',
                latitude: '',
                address_id: ''
            },
            formCopy: '',
            addressId: '',
        }
    },
    created() {
        this.getAddressList()
        this.formCopy = Object.assign({}, this.form)
    },
    methods: {
        handleClose(val){
            this.form = Object.assign({}, this.formCopy)
        },

        edit(form) {
            form.is_default = Boolean(form.is_default)
            this.form = form
            this.dialogVisible = true
        },
        
        //获取地址列表
        async getAddressList() {
            const res = await this.$http.get('/user.address', {
                page: this.page,
                size: 1000
            })
            if (res.code !== 0) {
                return this.$message.error(res.message);
            }
            this.addressList = res.data.list
        },

        // 提交
        async formSubmit() {
            let form = this.form
            form.is_default = Number(form.is_default)
            let region = form.region || []
            form.province = region.shift() || ''
            form.city = region.shift() || ''
            form.district = region.shift() || ''
            console.log(JSON.stringify(form))
            // this.dynamicForm.forEach(item => form[item.field] = item.value)
            const api = '/user.address/' + (form.address_id ? 'edit' : 'add')
            const res = await this.$http.post(api, form)
            if (res.code == 0) {
                this.dialogVisible = false
                this.addressId = ''
                this.getAddressList()
            }else{
                return this.$message.error(res.message)
            }
        },

        //删除
        async deleteAddress(address) {
            this.$confirm('此操作将永久删除, 是否继续?', '提示', {
                confirmButtonText: '確定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.deleteAddressItem(address)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })      
            })
        },

        async deleteAddressItem(address) {
            const res = await this.$http.post('/user.address/delete', {
                address_id: address.address_id
            });
            if (res.data.code !== 0) {
                 this.$message({type: 'success', message: '删除成功!'})
            }else{
                return this.$message.error(res.message)
            }
            this.getAddressList()
        }
    }
}
